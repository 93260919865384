import { useContext, useState, useMemo } from "preact/hooks";
import { Text } from "preact-i18n";
import CurrencyInput from "react-currency-input";

import { Configs } from "../../helpers/context";
import { handleCustomTipChange } from "../../helpers/customPrice";
import { getFormattingOptions } from "../../helpers/formatters/formatting-options";
import { formatIntegerCurrencyString } from "../../helpers/formatters";
import { getOrderTotalMinusTipAndTax, getOrderTippableTotal, getOrderTotal } from "../../helpers/order";

import "../../style/TipsForm.css";
import { Fragment } from "preact";

const TipsForm = ({ payLink, order }) => {
  const configsContext = useContext(Configs);
  const amount = getOrderTippableTotal(order);
  const [selected, setSelected] = useState("");
  const [currentTipAmount, setCurrentTipAmount] = useState(0);
  const [isCustomSelected, setIsCustomSelected] = useState(false);
  const [isNoTipSelected, setIsNoTipSelected] = useState(false);

  const orderOptions = useMemo(() => {
    const orderTax = order?.isLegacy ? order?.order?.value?.amounts?.taxTotal : order?.order?.value?.totals?.taxTotal?.value;
    const isOrderTaxDefined = (payLink?.order?.taxes?.length || 0) > 0;
    const isTipOptionSelected = order?.tip?.value || isNoTipSelected || isCustomSelected;

    return {
      orderTax,
      isOrderTaxDefined,
      isTipOptionSelected,
    }
  }, [order?.isLegacy, order?.order?.value?.amounts?.taxTotal, order?.order?.value?.totals?.taxTotal?.value, payLink?.order?.taxes?.length, order?.tip?.value, isNoTipSelected, isCustomSelected])

  const formattingOptions = getFormattingOptions(configsContext?.configs?.ecommerceLocaleLanguage);

  const amountString = formatIntegerCurrencyString(
    getOrderTotal(order),
    payLink.currency,
    configsContext?.configs?.ecommerceLocaleLanguage,
  );

  function handleTipSelected(tipType, tipValue, name) {
    setIsCustomSelected(false);
    setIsNoTipSelected(false);
    let tip;

    setSelected(name);

    if (tipType === "P") {
      tip = Math.round((tipValue / 100) * amount);
    } else {
      tip = Math.round(tipValue);
    }

    order.tip.value = tip;
    setCurrentTipAmount(tip);
  }

  if (
    (!payLink?.metadata?.allowTips ||
    payLink?.metadata?.allowTips === "0" ||
    payLink?.enableGratuity === 0 ||
    payLink?.enableGratuity === false ||
    payLink?.enableGratuity === "0") && !orderOptions.isOrderTaxDefined
  ) {
    return;
  }

  const selectedClassname = "tip-button selected-tip-button";
  const defaultButtonStyle = "tip-button";

  const customTipAmountForm = (
    <div className="tip-form-input">
      <form>
        <CurrencyInput
          className="custom-amount-input tip-form-custom-input"
          value={currentTipAmount === order?.tip?.value ? currentTipAmount / 100 : order?.tip?.value / 100}
          thousandSeparator={formattingOptions.thousandsSeparator}
          decimalSeparator={formattingOptions.decimalSeparator}
          prefix={formattingOptions.symbolPrefix}
          suffix={formattingOptions.symbolSuffix}
          allowNegativeValue={false}
          onChangeEvent={(_event, newValue) => {
            const intValue = handleCustomTipChange(
              configsContext?.configs?.ecommerceLocaleLanguage,
              newValue,
            );
            setCurrentTipAmount(intValue);
            setSelected("");
            order.tip.value = intValue;
          }}
        />
      </form>
    </div>
  );

  const customAndNoTipButtons = (
    <div className="tip-form-options">
      <button
        className={isNoTipSelected ? selectedClassname : defaultButtonStyle}
        onClick={() => {
          setIsNoTipSelected(true);
          setIsCustomSelected(false);
          setCurrentTipAmount(0);
          setSelected("");
          order.tip.value = 0;
        }}
      >
        <div className="tip-form-custom-text">
          <Text id="NO_TIP">No Tip</Text>
        </div>
      </button>
      <button
        className={isCustomSelected ? selectedClassname : defaultButtonStyle}
        onClick={() => {
          setIsCustomSelected(true);
          setIsNoTipSelected(false);
          setSelected("");
        }}
      >
        <div className="tip-form-custom-text">
          <Text id="CUSTOM_TIP">Custom Tip</Text>
        </div>
      </button>
    </div>
  );

  const totals = (
    <div className="tip-form-totals">
      {(orderOptions.isTipOptionSelected || orderOptions.isOrderTaxDefined) ? (
        <Fragment>
          <div className="tip-form-subtotal">
            <span style="float:left;">
              <Text id="SUBTOTAL">Subtotal</Text>
            </span>
            <span style="float:right;">
              {formatIntegerCurrencyString(
                getOrderTotalMinusTipAndTax({order, isLegacy: order?.isLegacy}),
                configsContext?.configs?.currency,
                configsContext?.configs?.ecommerceLocaleLanguage,
              )}
            </span>
            <div style="clear:both;"></div>
          </div>
            {orderOptions.isOrderTaxDefined ? (
                <div className="tip-form-tax">
                <span style="float:left;">
                  <Text id="Tax">Tax</Text>
                </span>
                <span style="float:right;">
                  {formatIntegerCurrencyString(
                    orderOptions.orderTax,
                    configsContext?.configs?.currency,
                    configsContext?.configs?.ecommerceLocaleLanguage,
                  )}
                </span>
                <div style="clear:both;"></div>
              </div>
            ) : null}
            {orderOptions.isTipOptionSelected ? (
                <div className="tip-form-tip">
                <span style="float:left;">
                  <Text id="TIP">Tip</Text>
                </span>
                <span style="float:right;">
                  {formatIntegerCurrencyString(
                    order?.tip?.value,
                    configsContext?.configs?.currency,
                    configsContext?.configs?.ecommerceLocaleLanguage,
                  )}
                </span>
                <div style="clear:both;"></div>
              </div>
            ) : null}
        </Fragment>
      ) : null}
      <div class="order-summary-divider"></div>
      <div className="tip-form-total">
        <span style="float:left;">
          <Text id="TOTAL">Total</Text>
        </span>
        <span style="float:right;">{amountString}</span>
        <div style="clear:both;"></div>
      </div>
    </div>
  );

  if (payLink?.enableSmartTip === "1") {
    if (amount < payLink?.smartTipThreshold) {
      if(payLink?.smartTipType === "A") {
        if (!payLink?.smartTipAmount1 || !payLink?.smartTipAmount2 || !payLink?.smartTipAmount3) {
          return;
        }
    
        return (
          <div className="tip-form">
            <div className="tip-form-label">
              <label>
                <Text id="TIP_LABEL">Add a Tip</Text>
              </label>
            </div>
            <div className="tip-form-options">
              <button
                className={selected === "smartTipAmount1" && order.tip.value !==0 ? selectedClassname : defaultButtonStyle}
                onClick={() => handleTipSelected(payLink?.smartTipType, payLink?.smartTipAmount1, "smartTipAmount1")}
              >
                <div className="tip-form-amount-text">
                  {formatIntegerCurrencyString(
                    payLink.smartTipAmount1,
                    configsContext?.configs?.currency,
                    configsContext?.configs?.ecommerceLocaleLanguage,
                  )}
                </div>
              </button>
              <button
                className={selected === "smartTipAmount2" && order.tip.value !==0 ? selectedClassname : defaultButtonStyle}
                onClick={() => handleTipSelected(payLink?.smartTipType, payLink?.smartTipAmount2, "smartTipAmount2")}
              >
                <div className="tip-form-amount-text">
                  {formatIntegerCurrencyString(
                    payLink.smartTipAmount2,
                    configsContext?.configs?.currency,
                    configsContext?.configs?.ecommerceLocaleLanguage,
                  )}
                </div>
              </button>
              <button
                className={selected === "smartTipAmount3" && order.tip.value !==0 ? selectedClassname : defaultButtonStyle}
                onClick={() => handleTipSelected(payLink?.smartTipType, payLink?.smartTipAmount3, "smartTipAmount3")}
              >
                <div className="tip-form-amount-text">
                  {formatIntegerCurrencyString(
                    payLink.smartTipAmount3,
                    configsContext?.configs?.currency,
                    configsContext?.configs?.ecommerceLocaleLanguage,
                  )}
                </div>
              </button>
            </div>
            {customAndNoTipButtons}
            {isCustomSelected ? customTipAmountForm : null}
            {totals}
          </div>
        );
      } else if(payLink?.smartTipType === "P") {
        if (!payLink?.smartTipPercent1 || !payLink?.smartTipPercent2 || !payLink?.smartTipPercent3) {
          return;
        }
        return (
          <div className="tip-form">
            <div>
              <label className="tip-form-label">
                <Text id="TIP_LABEL">Add a Tip</Text>
              </label>
            </div>
            <div className="tip-form-options">
              <button
                className={selected === "smartTipPercent1" && order.tip.value !==0 ? selectedClassname : defaultButtonStyle}
                onClick={() =>
                  handleTipSelected(payLink?.smartTipType, payLink?.smartTipPercent1, "smartTipPercent1")
                }
              >
                <div className="tip-form-percent-text">{payLink.smartTipPercent1}%</div>
                <div className="tip-form-percent-amount-text">
                  {formatIntegerCurrencyString(
                    (payLink.smartTipPercent1 / 100) * amount,
                    configsContext?.configs?.currency,
                    configsContext?.configs?.ecommerceLocaleLanguage,
                  )}
                </div>
              </button>
              <button
                className={selected === "smartTipPercent2" && order.tip.value !==0 ? selectedClassname : defaultButtonStyle}
                onClick={() =>
                  handleTipSelected(payLink?.smartTipType, payLink?.smartTipPercent2, "smartTipPercent2")
                }
              >
                <div className="tip-form-percent-text">{payLink.smartTipPercent2}%</div>
                <div className="tip-form-percent-amount-text">
                  {formatIntegerCurrencyString(
                    (payLink.smartTipPercent2 / 100) * amount,
                    configsContext?.configs?.currency,
                    configsContext?.configs?.ecommerceLocaleLanguage,
                  )}
                </div>
              </button>
              <button
                className={selected === "smartTipPercent3" && order.tip.value !==0 ? selectedClassname : defaultButtonStyle}
                onClick={() =>
                  handleTipSelected(payLink?.smartTipType, payLink?.smartTipPercent3, "smartTipPercent3")
                }
              >
                <div className="tip-form-percent-text">{payLink.smartTipPercent3}%</div>
                <div className="tip-form-percent-amount-text">
                  {formatIntegerCurrencyString(
                    (payLink.smartTipPercent3 / 100) * amount,
                    configsContext?.configs?.currency,
                    configsContext?.configs?.ecommerceLocaleLanguage,
                  )}
                </div>
              </button>
            </div>
            {customAndNoTipButtons}
            {isCustomSelected ? customTipAmountForm : null}
            {totals}
          </div>
        );
      }
    }
  }

  if (payLink?.tipType1 === "P") {
    if ((!payLink?.tipPercent1 || !payLink?.tipPercent2 || !payLink?.tipPercent3)) {
      return;
    }

    return (
      <div className="tip-form">
        {(payLink?.metadata?.allowTips || payLink?.metadata?.allowTips === "1") ? (
          <>
            <div>
            <label className="tip-form-label">
              <Text id="TIP_LABEL">Add a Tip</Text>
            </label>
          </div>
          <div className="tip-form-options">
            <button
              className={selected === "tipPercent1" && order.tip.value !==0 ? selectedClassname : defaultButtonStyle}
              onClick={() =>
                handleTipSelected(payLink?.tipType1, payLink?.tipPercent1, "tipPercent1")
              }
            >
              <div className="tip-form-percent-text">{payLink.tipPercent1}%</div>
              <div className="tip-form-percent-amount-text">
                {formatIntegerCurrencyString(
                  (payLink.tipPercent1 / 100) * amount,
                  configsContext?.configs?.currency,
                  configsContext?.configs?.ecommerceLocaleLanguage,
                )}
              </div>
            </button>
            <button
              className={selected === "tipPercent2" && order.tip.value !==0 ? selectedClassname : defaultButtonStyle}
              onClick={() =>
                handleTipSelected(payLink?.tipType1, payLink?.tipPercent2, "tipPercent2")
              }
            >
              <div className="tip-form-percent-text">{payLink.tipPercent2}%</div>
              <div className="tip-form-percent-amount-text">
                {formatIntegerCurrencyString(
                  (payLink.tipPercent2 / 100) * amount,
                  configsContext?.configs?.currency,
                  configsContext?.configs?.ecommerceLocaleLanguage,
                )}
              </div>
            </button>
            <button
              className={selected === "tipPercent3" && order.tip.value !==0 ? selectedClassname : defaultButtonStyle}
              onClick={() =>
                handleTipSelected(payLink?.tipType1, payLink?.tipPercent3, "tipPercent3")
              }
            >
              <div className="tip-form-percent-text">{payLink.tipPercent3}%</div>
              <div className="tip-form-percent-amount-text">
                {formatIntegerCurrencyString(
                  (payLink.tipPercent3 / 100) * amount,
                  configsContext?.configs?.currency,
                  configsContext?.configs?.ecommerceLocaleLanguage,
                )}
              </div>
            </button>
          </div>
          {customAndNoTipButtons}
          {isCustomSelected ? customTipAmountForm : null}
        </>) : null}
        {totals}
      </div>
    );
  } else if (payLink?.tipType1 === "A") {
    if (!payLink?.tipAmount1 || !payLink?.tipAmount2 || !payLink?.tipAmount3) {
      return;
    }

    return (
      <div className="tip-form">
        <div className="tip-form-label">
          <label>
            <Text id="TIP_LABEL">Add a Tip</Text>
          </label>
        </div>
        <div className="tip-form-options">
          <button
            className={selected === "tipAmount1" && order.tip.value !==0 ? selectedClassname : defaultButtonStyle}
            onClick={() => handleTipSelected(payLink?.tipType1, payLink?.tipAmount1, "tipAmount1")}
          >
            <div className="tip-form-amount-text">
              {formatIntegerCurrencyString(
                payLink.tipAmount1,
                configsContext?.configs?.currency,
                configsContext?.configs?.ecommerceLocaleLanguage,
              )}
            </div>
          </button>
          <button
            className={selected === "tipAmount2" && order.tip.value !==0 ? selectedClassname : defaultButtonStyle}
            onClick={() => handleTipSelected(payLink?.tipType1, payLink?.tipAmount2, "tipAmount2")}
          >
            <div className="tip-form-amount-text">
              {formatIntegerCurrencyString(
                payLink.tipAmount2,
                configsContext?.configs?.currency,
                configsContext?.configs?.ecommerceLocaleLanguage,
              )}
            </div>
          </button>
          <button
            className={selected === "tipAmount3" && order.tip.value !==0 ? selectedClassname : defaultButtonStyle}
            onClick={() => handleTipSelected(payLink?.tipType1, payLink?.tipAmount3, "tipAmount3")}
          >
            <div className="tip-form-amount-text">
              {formatIntegerCurrencyString(
                payLink.tipAmount3,
                configsContext?.configs?.currency,
                configsContext?.configs?.ecommerceLocaleLanguage,
              )}
            </div>
          </button>
        </div>
        {customAndNoTipButtons}
        {isCustomSelected ? customTipAmountForm : null}
        {totals}
      </div>
    );
  }
};

export default TipsForm;
